import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { CatalogueService } from '../../services/catalogue/catalogue.service';
import { environment } from 'src/environments/environment';
import * as fbAuth from '@ionic-native/firebase-authentication';
import { RemoteConfigService } from '../remote-config/remote-config.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  constructor(
    private authService: AuthService,
    private catalogueService: CatalogueService,
    private remoteConfig: RemoteConfigService,
    private storageService: StorageService
  ) {}
  program: any;
  programs: any;
  tokenCatalogue: any;

  catalogue = () => {
    return {
      search_key:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaWx0ZXJzIjp7ImFsbCI6W3sicHVibGlzaGVkIjoxfSx7InByb2dyYW0iOiJjb21wYW5pZXMvZ2x1a3lfZ3JvdXAvcHJvZ3JhbXMvbXZwX2dueCJ9XX0sImFwaV9rZXlfbmFtZSI6InNlYXJjaC1rZXkiLCJpYXQiOjE2MDYxNjQzMzV9._hOKQPRsQrQEme5JYpIihila_IMjp0fqCq6UV7W7kX8',
      engine_name: 'gnx-diageo-legendario',
      base_url: `${environment.gluky.catalogueApiBasePath}/api/as/v1/engines`,
    };
  };

  async catalogueNew() {
    this.tokenCatalogue = await this.catalogueService.sendToken();
    await this.storageService.set('elasticToken', this.tokenCatalogue);
    return {
      search_key: this.tokenCatalogue.token,
      engine_name: 'gnx-diageo-legendario',
      base_url: `${environment.gluky.catalogueApiBasePath}/api/as/v1/engines`,
    };
  }

  orders() {
    if (this.authService.programId && this.authService.programId[0]) {
      this.programs = this.authService.programId[0].split('/');
      this.program = this.programs.slice(-1).pop();
    }

    return {
      companyId: environment.gluky.companyId,
      programId: this.program,
      appId: environment.gluky.app_id,
      uid: this.authService.userGnxId,
      base_url: environment.gluky.ordersApiBasePatch,
      api_key: this.authService.apiKey,
      token: this.authService.currentAuthTokenValue,
      products_url: environment.gluky.productsUrl,
    };
  }
  registerChat() {
    if (this.authService.programId && this.authService.programId[0]) {
      this.programs = this.authService.programId[0].split('/');
      this.program = this.programs.slice(-1).pop();
    }
    return {
      appId: environment.gluky.app_id,
      programId: this.program,
      companyId: environment.gluky.companyId,
      apiKey: this.authService.apiKey,
      baseUrl: environment.gluky.usersApiBasePath,
      fbAuth,
    };
  }

  async challengesStrapi() {
    const res = await this.remoteConfig.getIsStrapi().toPromise();
    return {
      base_url: res
        ? `${environment.gluky.challengesStrapiApiBasePath}`
        : `${environment.gluky.cmsBffApiBasePath}`,
    };
  }

  historyPoints() {}
}
