// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  appName: 'GNX Diageo Legendario',
  gmApiKey: 'AIzaSyCsh212JOWHNkkMMsBeqtW2n2QnZZZXHSY',
  firebaseConfig: {
    apiKey: 'AIzaSyC1aR43QQUZy32LAkjh5xdLbE8PenJPsp0',
    authDomain: 'gnx-diageo-legendarios.firebaseapp.com',
    projectId: 'gnx-diageo-legendarios',
    storageBucket: 'gnx-diageo-legendarios.appspot.com',
    messagingSenderId: '273555471609',
    appId: '1:273555471609:web:fabb2f829be1d5faab3628',
    measurementId: 'G-XWTETYBD9Y',
  },
  gluky: {
    app_id: 'gnx_diageo_legendario',
    programId: 'gnx_diageo_prd',
    companyId: 'gnx_diageo_prd',
    apikey: 'AIzaSyCsh212JOWHNkkMMsBeqtW2n2QnZZZXHSY',
    apikeyPwa: 'AIzaSyCsh212JOWHNkkMMsBeqtW2n2QnZZZXHSY',
    apiKeyWeb: 'AIzaSyCsh212JOWHNkkMMsBeqtW2n2QnZZZXHSY',
    apiKeyAndroid: 'AIzaSyAeXX10kUK2RiZX9g3x3qz3mboARYsSxdU',
    usersApiBasePath: 'https://users-api.glukynet.com/v2',
    ordersApiBasePatch: 'https://orders-api.glukynet.com/v1',
    pointsApiBasePath: 'https://bank-api.glukynet.com/v1',
    bffApiBasePath: 'https://bff-api.cms.glukynet.com/v1',
    bffApiBasePathV2: 'https://bffv2-cms.glukynet.com/v2',
    productsApiBasePath: 'https://products-front-api.glukynet.com/v1',
    kpiFrontApiBasePath: 'https://kpis-front.glukynet.com/v1',
    catalogueApiBasePath:
      'https://fb07883f71224ce39a631c312f9e280b.ent-search.us-east1.gcp.elastic-cloud.com',
    challengesStrapiApiBasePath:
      'https://bff-api.cms.glukynet.com/v1/contents/challenges',
    productsUrl: 'https://products-front-api.glukynet.com/v1',
    recognitionsUrl: 'recognitions-front.glukynet.com',
    algoliaSearchUrl:
      'https://instantsearch-us-east1-czsrv8lj.ue.gateway.dev/v1/front/simple/app/',
    pointsNewApiBasePath:
      'https://pointz-main-test-us-east1-5202s9wg.ue.gateway.dev/main/v1',
    algoliaSearch:
      'https://instantsearch-us-east1-czsrv8lj.ue.gateway.dev/v1/front/simple/app/',
    cmsBffApiBasePath: 'https://cms-bff-v3-2kntzite.ue.gateway.dev/v3',
    cloudinaryUrl: 'http://res.cloudinary.com/glukygroup/',
  },
  country: 'mx',
  img_profile_default:
    'https://firebasestorage.googleapis.com/v0/b/gnx-mvp.appspot.com/o/defaults%2Fdefault-user.jpg?alt=media&token=15fbfa41-6ba2-4a88-8530-e5e5f41e45a4',
  phoneIndicator: '+52',
  clevertapAccountId: '4R6-87K-895Z',
  pathNotification: 'https://notifications-api.glukynet.com/v1',
  dinamycLink: {
    url: 'https://mvpimproved.page.link/C1mr',
    packageName: 'com.gluky.mvpimproved',
  },
  geochallenges: {
    proximityRadiusInMeters: 800,
  },
  saml: {
    provider: 'saml.bancolombia',
    url: 'https://gnx-bancolombia-reconocerte.firebaseapp.com/__/auth/handler',
  },
  emblu: {
    endpoint:
      'https://us-central1-gnx-bavaria-blovers-prd.cloudfunctions.net/getEmblueToken',
    associatePushToEmail:
      'https://us-central1-gnx-bavaria-blovers-prd.cloudfunctions.net/setAssociatePushToEmail',
    newContact:
      'https://us-central1-gnx-bavaria-blovers-prd.cloudfunctions.net/setNewContact',
  },
  frontendPuntoRedApi:
    'https://puntored-frontend-stg-us-east1-1g6o62sh.ue.gateway.dev/v1',
  public_id_cloudinary: '/Brands/Brands-test/',
  cloudinary_config: {
    cloud_name: 'glukygroup',
    apiKey: '112786663666772',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
